let home = [
  {
    path: '/',
    name: '首页',
    component: () => import('@/pages/home')
  },
  {
    path: '/news_list',
    name: '新闻动态',
    component: () => import('@/pages/news_list')
  },
  {
    path: '/results_show',
    name: '成果展示',
    component: () => import('@/pages/results_show')
  },
  {
    path: '/study_section',
    name: '学习园地',
    component: () => import('@/pages/study_section')
  },
  {
    path: '/news_detail',
    name: '文章详情',
    component: () => import('@/pages/news_detail')
  },
  {
    path: '/authorization_page',
    name: '用户授权',
    component: () => import('@/pages/authorization_page')
  }
]

export default home