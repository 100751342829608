<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  data () {
    return {
      list: ''
    }
  }
}
</script>

<style lang="less">
  body,html {
    height: 100%;
    // overflow: hidden;
  }
  * {
    margin: 0;
    padding: 0;
  }
  li {
    list-style: none;
  }
#app {
  height: 100%;
  width: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
