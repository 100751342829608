import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'vant/lib/index.css'
import 'amfe-flexible';
import {Service} from '@/http/axios.js'
Vue.prototype.$https = Service
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
