import axios from 'axios'
import { Toast } from 'vant';
// const ConfigBaseURL = 'http://testapi.kmyczxpg.com/' //默认路径，这里也可以使用env来判断环境
const ConfigBaseURL = 'https://forestfireapi.yncakj.cn//' //默认路径，这里也可以使用env来判断环境
//使用create方法创建axios实例
export const Service = axios.create({
    timeout: 7000, // 请求超时时间
    baseURL: ConfigBaseURL,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})
// 添加请求拦截器
Service.interceptors.request.use(config => {
    Toast.loading({
        message: "加载中...",
        duration: 0,
        overlay: true,
        forbidClick: true,
    });
    return config
})
// 添加响应拦截器
Service.interceptors.response.use(response => {
    Toast.clear();
    // console.log(response)
    if (response.data.code == 500 || response.data.code === 401) {
        Toast({
            message: response.data.msg,
            position: 'bottom',
            // onClose(){location.href = "./login"}
        });
    }
    return response.data
}, error => {
    console.log('TCL: error', error)
    const msg = error.Message !== undefined ? error.Message : ''
    Toast({
        message: '网络错误' + msg,
        position: 'bottom',
       // onClose(){location.href = "./login"}
    });

    Toast.clear();
    return Promise.reject(error)
})
