let routesArr = []

let routes = []

const requireComponent = require.context(
  './modules', true, /\.js$/
)

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  routesArr.push(componentConfig.default || componentConfig)
})

routesArr.forEach(route => {
  routes = routes.concat(route)
})

export default routes