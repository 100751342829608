
import Router from 'vue-router'
import Vue from 'vue'
import routes from './routes'

const mode = 'history'
const hashbang = true

const router = new Router({
  hashbang,
  mode,
  routes
})
Vue.use(Router)
router.beforeEach((to, from, next) => {
  // 设置页面title
  if (to.name) {
    document.title = to.name
  }
  next()
})
window.$router = router

export default router